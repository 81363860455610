import React from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { patientModalStyles } from '@utils/constants'
import PropTypes from 'prop-types'

const ViewNoteModal = ({ selectedNote, setSelectedNote }) => {
  return (
    <Modal
      open={!!selectedNote}
      onClose={() => setSelectedNote(null)}
      aria-labelledby="Patient Note Details Modal"
      aria-describedby="This modal contains the detailed information regarding the currently selected note"
    >
      <Box sx={patientModalStyles}>
        <Typography variant="h4" id="note-details-modal__title">
          Note Details
        </Typography>
        <Typography
          id="note-details-modal__sub-clinician"
          variant="h6"
          component="h2"
        >
          Created by:{' '}
          {`${
            (selectedNote?.clinicvu_user_on_portals?.clinicvu_user
              ?.first_name || 'NA') +
            ' ' +
            (selectedNote?.clinicvu_user_on_portals?.clinicvu_user?.last_name ||
              'NA')
          }`}
        </Typography>
        <Typography
          id="note-details-modal__sub-date"
          variant="h6"
          component="h2"
        >
          Created at:{' '}
          {`${moment(selectedNote?.created_at).format(
            'MMMM Do YYYY, h:mm:ss a'
          )}`}
        </Typography>
        <hr />
        <Typography sx={{ whiteSpace: 'pre-wrap', overflow: 'scroll', maxHeight: 500 }}>{`${selectedNote?.text}`}</Typography>
      </Box>
    </Modal>
  )
}

ViewNoteModal.propTypes = {
  selectedNote: PropTypes.object,
  setSelectedNote: PropTypes.func
}

export default ViewNoteModal
