import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Badge, IconButton, Tooltip } from '@mui/material'
import { MdOutlineSdCardAlert } from 'react-icons/md'
import { TbAlertTriangle } from 'react-icons/tb'
import styles from './PatientAlerts.module.scss'
import PropTypes from 'prop-types'

const PatientAlerts = ({ alerts, patientId }) => {
  const navigate = useNavigate()
  const [outOfRangeAlerts, setOutOfRangeAlerts] = useState([])
  const [missingAlerts, setMissingAlerts] = useState([])

  useEffect(() => {
    filterAlerts(alerts)
  }, [alerts])

  const filterAlerts = (alerts) => {
    setMissingAlerts([])
    setOutOfRangeAlerts([])
    Object.keys(alerts).forEach((alert) => {
      if (alert.includes('Missing')) {
        setMissingAlerts((missingAlerts) => [...missingAlerts, alert])
      } else if (alert.includes('Out of range')) {
        setOutOfRangeAlerts((missingAlerts) => [...missingAlerts, alert])
      }
    })
  }

  const formatColors = (action) => {
    action = action.replace('Missing ', '')
    action = action.replace('Out of range ', '')
    return COLOR_MAPPING[action]
  }

  const COLOR_MAPPING = {
    'Blood Pressure': '#ffba00',
    Temperature: '#ea540b',
    SPO2: '#4285f4',
    ECG: '#69be28',
    Weight: 'grey',
    Survey: 'grey'
  }

  const goToAlert = (alert) => {
    navigate(`/patient/${patientId}`, { state: { alert } })
  }

  return (
    <div className={styles.alerts}>
      {outOfRangeAlerts.map((alert, index) => (
        <div
          key={index}
          className={styles.flag}
          onClick={() => goToAlert(alerts[alert].latest_alert)}
        >
          <Tooltip title={alert}>
            <Badge badgeContent={alerts[alert].count}>
              <IconButton
                size="small"
                className="range-alert"
                style={{ color: formatColors(alert) }}
              >
                <TbAlertTriangle />
              </IconButton>
            </Badge>
          </Tooltip>
        </div>
      ))}
      {missingAlerts.map((alert, index) => (
        <div
          key={index}
          className={styles.flag}
          onClick={() => goToAlert(alerts[alert].latest_alert)}
        >
          <Tooltip title={alert}>
            <Badge
              badgeContent={alerts[alert].count}
              style={{ opacity: '0.6' }}
            >
              <IconButton
                size="small"
                className="missing-alert"
                style={{ color: formatColors(alert) }}
              >
                <MdOutlineSdCardAlert />
              </IconButton>
            </Badge>
          </Tooltip>
        </div>
      ))}
    </div>
  )
}

export default PatientAlerts

PatientAlerts.propTypes = {
  alerts: PropTypes.object,
  patientId: PropTypes.string
}
