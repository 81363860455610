/* eslint-disable multiline-ternary */
// todo: re-add the appointments table
// todo: patients are being grouped based on their sign up date, this should later be modified to earliest association time with careplan.

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from '@mui/material'

import TopSection from '@shared/top-section/TopSection'
import { generateMonthlyPatientsGraphData } from '@utils/functions'
import * as _ from 'lodash'
import moment from 'moment'
import React, { useContext, useState, useEffect } from 'react'
import { Line } from 'react-chartjs-2'

import RegisterUser from '@shared/register-user/RegisterUser'
import styles from './Clinic.module.scss'

import { CurrentPortalContext } from '@context/CurrentPortalContext'
import { UserContext } from '@context/UserContext'
import { useLocation } from 'react-router-dom'
import { useQuery } from 'react-query'
import ClinicvuService from 'services/Clinicvu/Clinicvu.service'

const getCarePlans = (clinicvuUsersOnPortalsOnCarePlans) => {
  const clinicvuUsersOnPortalsOnCarePlansMapped =
    clinicvuUsersOnPortalsOnCarePlans.map(
      (clinicvuUsersOnPortalsOnCarePlan) =>
        clinicvuUsersOnPortalsOnCarePlan.care_plan?.name
    )
  return clinicvuUsersOnPortalsOnCarePlansMapped.join(',')
}

const Clinic = () => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [showNew, setShowNew] = useState(false)
  const [selectedClinicvuUser, setSelectedClinicvuUser] = useState(null)

  const { currentPortal } = useContext(CurrentPortalContext)
  const { me } = useContext(UserContext)
  const location = useLocation()

  const currentPortalName = me?.portals[currentPortal]?.portal?.name

  const isManager =
    me?.portals[currentPortal]?.role === ('Manager' || 'manager') || false

  useEffect(() => {
    toggleEditOrNew(null, false)
  }, [location.key])

  const handlePageChange = (e, newPage) => {
    setPage(newPage)
  }

  const handleRowsPerPageChange = (e) => {
    const newRowsPerPage = parseInt(e.target.value, 10)
    setRowsPerPage(newRowsPerPage)
    setPage(0)
  }
  const toggleEditOrNew = (user, toggle) => {
    setSelectedClinicvuUser(user)
    setShowNew(toggle)
  }

  const { data: clinicvuUsersOnPortal } = useQuery(
    ['clinicvuUsersOnPortal'],
    async () => {
      const res = await ClinicvuService.getClinicvuUsersOnPortal(
        currentPortalName
      )
      if (res.status !== 200) {
        throw new Error(
          `Was not able to fetch clinicvu users data for portal ${currentPortalName}`
        )
      }
      return res.data
    },
    {
      enabled: !!currentPortalName
    }
  )

  const currClinicvuUsersOnPortals =
    clinicvuUsersOnPortal?.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    ) || []

  const { data: patients } = useQuery(
    ['patients', me],
    async () => {
      const res = await ClinicvuService.getBiovuUsersOnPortal(currentPortalName)
      if (res.status !== 200) {
        throw new Error(
          `Was not able to fetch all patients on portal ${currentPortalName}`
        )
      }
      return res.data
    },
    { enabled: !!me }
  )

  const signUpTimes = patients?.map((patient) => patient.created_at) || []

  const monthlyPatients =
    _.chain(
      _.groupBy(signUpTimes, (date) => {
        const st = moment(date).startOf('month')
        return moment(st).format('MMM-YYYY').toLocaleUpperCase()
      })
    )
      .map((time, i) => ({
        month: i,
        patientsArr: time
      }))
      .sortBy((item) => moment(item.month, 'MMM-YYYY'))
      .value() || {}

  const cumulativeMonthlyPatients = monthlyPatients?.map(
    ((sum) => (mp) => {
      sum += mp?.patientsArr?.length
      return {
        month: mp.month,
        totalPatients: sum
      }
    })(0)
  )

  const headerText =
    selectedClinicvuUser === null ? 'Register user' : 'View User'
  return (
    <div className={styles.content}>
      <TopSection
        isDisabled={!isManager}
        btnFunction={toggleEditOrNew}
        showNew={showNew}
      ></TopSection>
      {!showNew ? (
        patients && (
          <div>
            <div className={styles.tiles}>
              <div className={styles.tile}>
                <img
                  className={styles.tile__icon}
                  src="/assets/svgs/patients.svg"
                  alt="Patients"
                />
                <div className={styles.tile__content}>
                  <div className={styles.tile__header}>All Patients</div>
                  <div className={styles.tile__text}>{patients.length}</div>
                </div>
              </div>
            </div>

            <div className={`${styles.tile} ${styles['tile--large']}`}>
              <div className={styles['tile__header--large']}>
                Your Clinic - Month by Month
              </div>
              <div className={styles.tile__graph}>
                <Line
                  options={{ maintainAspectRatio: false }}
                  data={() =>
                    generateMonthlyPatientsGraphData(cumulativeMonthlyPatients)
                  }
                />
              </div>
            </div>

            <div className={styles.margin}></div>

            <TableContainer componen={Paper}>
              <Table
                aria-label="ClinicVU Users on Portal Table"
                className={styles.table}
                stickyHeader
              >
                <TableHead className={styles.table__head}>
                  <TableRow>
                    <TableCell width="20%">Name</TableCell>
                    <TableCell width="30%">Email</TableCell>
                    <TableCell width="10%">Role</TableCell>
                    <TableCell width="30%">Care Plans</TableCell>
                    {isManager && <TableCell width="5%"></TableCell>}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {currClinicvuUsersOnPortals?.map(
                    (clinicvuUserOnPortal, index) => (
                      <TableRow
                        className={styles.tablerow}
                        key={index}
                        onClick={() =>
                          toggleEditOrNew(clinicvuUserOnPortal, true)
                        }
                      >
                        <TableCell>
                          {(clinicvuUserOnPortal.clinicvu_user?.first_name ||
                            'NA') +
                            ' ' +
                            (clinicvuUserOnPortal.clinicvu_user?.last_name ||
                              'NA')}
                        </TableCell>
                        <TableCell>
                          {clinicvuUserOnPortal.clinicvu_user?.email || 'NA'}
                        </TableCell>
                        <TableCell>
                          {clinicvuUserOnPortal?.role || 'NA'}
                        </TableCell>
                        <TableCell>
                          {clinicvuUserOnPortal?.clinicvu_users_on_portals_on_care_plans
                            ? getCarePlans(
                              clinicvuUserOnPortal?.clinicvu_users_on_portals_on_care_plans
                            )
                            : 'NA'}
                        </TableCell>
                        {isManager && (
                          <TableCell>
                            <Tooltip title="Edit ClinicVU User">
                              <button
                                className={styles.buttons__btn}
                                onClick={() =>
                                  toggleEditOrNew(clinicvuUserOnPortal, true)
                                }
                              >
                                <img src="/assets/svgs/pencil.svg" alt="Edit" />
                              </button>
                            </Tooltip>
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  )}
                </TableBody>

                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleRowsPerPageChange}
                      page={page}
                      onPageChange={handlePageChange}
                      count={clinicvuUsersOnPortal?.length || 0}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        )
      ) : (
        <>
          <div className={styles.header}>{headerText}</div>
          <RegisterUser
            btnFunction={toggleEditOrNew}
            user={selectedClinicvuUser}
          />
        </>
      )}
    </div>
  )
}

export default Clinic
