/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import TopSection from '@shared/top-section/TopSection'
import ContactForm from '@shared/contact-form/ContactForm'
import { AlertsData } from '@mock-data/alerts'
import { EditorState } from 'draft-js'
import Dropdown from 'react-dropdown'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Paper,
  Checkbox
} from '@mui/material'
import { PatientDataTypes } from '@utils/constants'

import 'react-dropdown/style.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import styles from './Alerts.module.scss'

const Alerts = () => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selectedPatient, setSelectedPatient] = useState()
  const [selectedCommunicationMethod, setSelectedCommunicationMethod] =
    useState('')
  const [open, setOpen] = useState(false)
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )

  const [carePlansFilter, setCarePlansFilter] = useState('All')
  const [missingFilter, setMissingFilter] = useState('All')
  const [outOfRangeFilter, setOutOfRangeFilter] = useState('All')

  const [alerts, setAlerts] = useState([])
  const [searchedAlerts, setSearchedAlerts] = useState([])
  const [displayedAlerts, setDisplayedAlerts] = useState([])

  const handlePageChange = (e, newPage) => {
    setPage(newPage)
    setDisplayedAlerts(
      searchedAlerts.slice(
        newPage * rowsPerPage,
        newPage * rowsPerPage + rowsPerPage
      )
    )
  }

  const handleRowsPerPageChange = (e) => {
    const newRowsPerPage = parseInt(e.target.value, 10)
    setRowsPerPage(newRowsPerPage)
    setPage(0)
    setDisplayedAlerts(searchedAlerts.slice(0, newRowsPerPage))
  }

  const handleClose = () => setOpen(false)
  const notifyPatient = (patientName) => {
    setSelectedPatient(patientName)
    setOpen(true)
  }

  const updateCarePlansFilter = (filter) => {
    setCarePlansFilter(filter.value)
    const newAlerts = alerts?.filter(
      (a) => a.carePlan === filter.value || filter.value === 'All'
    )
    setSearchedAlerts(newAlerts)
    setDisplayedAlerts(newAlerts.slice(0, rowsPerPage))
    setPage(0)
  }

  const updateMissingFilter = (filter) => {
    setMissingFilter(filter.value)
    const newAlerts = alerts?.filter(
      (a) => a.missing === filter.value || filter.value === 'All'
    )
    setSearchedAlerts(newAlerts)
    setDisplayedAlerts(newAlerts.slice(0, rowsPerPage))
    setPage(0)
  }

  const updateOutOfRangeFilter = (filter) => {
    setOutOfRangeFilter(filter.value)
    const newAlerts = alerts?.filter(
      (a) => a.outOfRange === filter.value || filter.value === 'All'
    )
    setSearchedAlerts(newAlerts)
    setDisplayedAlerts(newAlerts.slice(0, rowsPerPage))
    setPage(0)
  }

  // get all alerts
  useEffect(() => {
    setAlerts(AlertsData)
    setSearchedAlerts(AlertsData)
    setDisplayedAlerts(AlertsData)
  }, [])

  return (
    <div className={styles.content}>
      <TopSection></TopSection>
      <div className={styles.header}>Communication Hub</div>

      <TableContainer component={Paper}>
        <Table aria-label="Alerts Table" sx={{ minHeight: '200px' }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  inputProps={{
                    'aria-label': 'select all'
                  }}
                />
              </TableCell>
              <TableCell width="20%">Patient Name</TableCell>
              <TableCell width="10%">
                Care Plans
                {alerts && (
                  <Dropdown
                    options={['CHF', 'LSD', 'TTT', 'All']}
                    value={carePlansFilter}
                    onChange={(filter) => updateCarePlansFilter(filter)}
                  />
                )}
              </TableCell>
              <TableCell width="10%">
                Missing
                {alerts && (
                  <Dropdown
                    options={PatientDataTypes}
                    value={missingFilter}
                    onChange={(filter) => updateMissingFilter(filter)}
                  />
                )}
              </TableCell>
              <TableCell width="10%">
                Out of Range
                {alerts && (
                  <Dropdown
                    options={PatientDataTypes}
                    value={outOfRangeFilter}
                    onChange={(filter) => updateOutOfRangeFilter(filter)}
                  />
                )}
              </TableCell>
              <TableCell width="30%">Actions</TableCell>
              <TableCell width="13%">
                <button onClick={() => notifyPatient()}>Notify All</button>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedAlerts.map((alert, index) => (
              <TableRow className={styles.tablerow} key={index}>
                <TableCell padding="checkbox">
                  <Checkbox color="primary" />
                </TableCell>
                <TableCell>
                  <div className={styles.username}>
                    <img
                      src="assets/user-icon.jpeg"
                      alt={`User ${alert.patientName}`}
                      className={styles['user-icon']}
                    />
                    <span className={styles.username__text}>
                      {alert.patientName}
                    </span>
                  </div>
                </TableCell>
                <TableCell>{alert.carePlan}</TableCell>
                <TableCell>{alert.missing}</TableCell>
                <TableCell>{alert.outOfRange}</TableCell>
                <TableCell>{alert.actions}</TableCell>
                <TableCell>
                  <button onClick={() => notifyPatient(alert.patientName)}>
                    Notify
                  </button>
                </TableCell>
                <TableCell>
                  <button className={styles.trash}>
                    {/* // TODO: Implement Delete Functionality  */}
                    <img src="/assets/svgs/red-trash-can.svg" alt="Delete" />
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={page}
                onPageChange={handlePageChange}
                count={searchedAlerts ? searchedAlerts.length : 0}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <ContactForm open={open} setOpen={setOpen} />
    </div>
  )
}

export default Alerts
