import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { BiArrowBack } from 'react-icons/bi'

import styles from './TopSection.module.scss'
import PropTypes from 'prop-types'

const TopSection = ({ btnFunction, isDisabled, showNew }) => {
  const location = useLocation()
  const [btnText, setBtnText] = useState('Enroll a New Patient')
  useEffect(() => {
    let tempText

    if (location.pathname === '/patients' && !showNew) { tempText = 'Enroll a New Patient' } else if (location.pathname === '/surveys' && !showNew) { tempText = 'Define a New Survey' } else if (location.pathname === '/files' && !showNew) { tempText = 'Define a new file' } else if (location.pathname === '/clinic' && !showNew) tempText = 'New User'
    else if (location.pathname === '/care-plans' && !showNew) { tempText = 'New Care Plan' } else if (location.pathname === '/portals' && !showNew) { tempText = 'New Portal' }
    setBtnText(tempText)
  }, [location, showNew])

  return (
    <div className={styles['top-section']}>
      <div className={styles['top-section--left-aligned']}>
        {showNew
          ? (
          <BiArrowBack
            onClick={() => btnFunction(null, false)}
            disabled={isDisabled}
            style={{ cursor: 'pointer', marginBottom: '13px' }}
          />
            )
          : null}
        {/* <div className={styles["top-section__search"]}>
          <img
            src="/assets/svgs/search.svg"
            alt="Search"
            className={styles["top-section__search-icon"]}
          />
          <input
            type="text"
            placeholder="Search"
            className={styles["top-section__search-input"]}
          />
        </div> */}
      </div>
      <div className={styles['top-section--right-aligned']}>
        {btnText && !showNew
          ? (
          <button
            className={styles['top-section__btn']}
            id="top-section__btn"
            onClick={() => btnFunction(null, true)}
            disabled={isDisabled}
          >
            <span className={styles['top-section__btn-text']}>{btnText}</span>
          </button>
            )
          : null}
        {/* <div className={styles["top-section__notifications"]}>
          <img src="/assets/svgs/notification.svg" alt="Notifications" />
          <button className={styles["top-section__notifications-btn"]}>
            19
          </button>
        </div>
        <div className={styles["top-section__profile"]}></div> */}
      </div>
    </div>
  )
}

TopSection.defaultProps = {
  isDisabled: false,
  showNew: false
}

TopSection.propTypes = {
  btnFunction: PropTypes.func,
  isDisabled: PropTypes.bool,
  showNew: PropTypes.bool
}

export default TopSection
