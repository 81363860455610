import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'

import PropTypes from 'prop-types'
import { Box, Button, MenuItem, Modal, TextField } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import styles from './EditPatientModal.scss'
import ClinicvuService from 'services/Clinicvu/Clinicvu.service'
import { ParamGender } from '@utils/constants'
import { isValidNumber } from '@utils/functions'

export default function EditPatientModal ({
  refetch,
  isOpen,
  setIsOpenEditPatientModal,
  biovuUserId,
  biovuUserOnPortalId,
  initialFirstName,
  initialLastName,
  initialSex,
  initialDOB,
  initialEmail,
  initialPhone,
  initialHealthNumber,
  initialHeight
}) {
  const [firstName, setFirstName] = useState(initialFirstName)
  const [lastName, setLastName] = useState(initialLastName)
  const [email, setEmail] = useState(initialEmail)
  const [phoneNumber, setPhone] = useState(initialPhone)
  const [healthNumber, setHealthNumber] = useState(initialHealthNumber)
  const [height, setHeight] = useState(initialHeight)
  const [dateOfBirth, setDateOfBirth] =
    initialDOB == null ? useState(null) : useState(new Date(initialDOB))
  const [sex, setSex] = useState(initialSex?.toLowerCase())

  const [firstNameInvalid, setFirstNameInvalid] = useState(false)
  const [lastNameInvalid, setLastNameInvalid] = useState(false)
  const [sexInvalid, setSexInvalid] = useState(false)
  const [phoneNumberInvalid, setPhoneNumberInvalid] = useState(false)
  const [dateOfBirthInvalid, setDateOfBirthInvalid] = useState(false)
  const [attempted, setAttempted] = useState(false)

  useEffect(() => {
    setFirstName(initialFirstName)
    setLastName(initialLastName)
    setSex(initialSex?.toLowerCase())
    setEmail(initialEmail)
    setPhone(initialPhone)
    setHealthNumber(initialHealthNumber)
    setHeight(initialHeight || '')
    setDateOfBirth(initialDOB ? new Date(initialDOB) : '')
  }, [
    initialFirstName,
    initialLastName,
    initialSex,
    initialDOB,
    initialPhone,
    initialHealthNumber,
    initialHeight
  ])

  useEffect(() => {
    // Attempted allows us to only flag empty fields after an attempted submit.
    if (attempted) {
      setFirstNameInvalid(firstName === '')
      setLastNameInvalid(lastName === '')
      setSexInvalid(sex === '')
      setDateOfBirthInvalid(!dateOfBirth)
      setPhoneNumberInvalid(!isValidNumber(phoneNumber))
    }
  }, [firstName, lastName, sex, attempted, phoneNumber, dateOfBirth])

  const saveUserInfo = useMutation(async (e) => {
    e.preventDefault()
    setAttempted(true)
    const dateOfBirthEpoch = new Date(dateOfBirth).getTime()
    if (
      firstName !== '' &&
      lastName !== '' &&
      sex !== '' &&
      isValidNumber(phoneNumber) &&
      dateOfBirth
    ) {
      const res = await ClinicvuService.updateBiovuUser(
        biovuUserId,
        biovuUserOnPortalId,
        phoneNumber,
        healthNumber,
        firstName,
        lastName,
        dateOfBirthEpoch,
        sex,
        height
      )
      if (res.status !== 200) {
        throw new Error('Was unable to save user information with id')
      }
      refetch()
      handleClose()
    }
  })

  const handleClose = () => {
    setFirstName(initialFirstName)
    setLastName(initialLastName)
    setSex(initialSex?.toLowerCase())
    setEmail(initialEmail)
    setPhone(initialPhone)
    setHealthNumber(initialHealthNumber)
    setHeight(initialHeight)
    setDateOfBirth(initialDOB ? new Date(initialDOB) : null)
    setIsOpenEditPatientModal(false)
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="Edit patient modal."
    >
      <Box className="box">
        <div className="header">Edit Patient Details</div>
        <div className="row">
          <TextField
            label="First Name*"
            variant="outlined"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            error={firstNameInvalid}
            size="small"
            helperText={firstNameInvalid ? 'First name is required' : ''}
          />
          <TextField
            label="Last Name*"
            variant="outlined"
            value={lastName}
            sx={{ marginLeft: '15px' }}
            onChange={(e) => setLastName(e.target.value)}
            error={lastNameInvalid}
            size="small"
            helperText={lastNameInvalid ? 'Last name is required' : ''}
          />
        </div>
        <div className="row">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Date of Birth *"
              inputFormat="DD/MM/YYYY"
              className={styles['date-of-birth']}
              value={dateOfBirth}
              onChange={(date) => setDateOfBirth(date)}
              renderInput={(params) => (
                <TextField error={dateOfBirthInvalid} {...params} />
              )}
              disableFuture={true}
            />
          </LocalizationProvider>
          <TextField
            label="Sex *"
            value={sex}
            onChange={(e) => setSex(e.target.value)}
            className={styles['date-of-birth']}
            sx={{ marginLeft: '15px', flexGrow: 2 }}
            error={sexInvalid}
            select
          >
            {Object.keys(ParamGender).map((gender) => {
              return (
                <MenuItem key={gender} value={gender}>
                  {ParamGender[gender]}
                </MenuItem>
              )
            })}
          </TextField>
        </div>
        <div className="row">
          <TextField
            label="Phone Number (+14166288463)"
            variant="outlined"
            sx={{ flexGrow: 2 }}
            value={phoneNumber}
            error={phoneNumberInvalid}
            onChange={(e) => setPhone(e.target.value)}
            size="small"
            helperText={phoneNumberInvalid ? 'Enter a valid phone number' : ''}
          />
          <TextField
            label="Patient Health Number"
            variant="outlined"
            value={healthNumber}
            onChange={(e) => setHealthNumber(e.target.value)}
            size="small"
            style={{ marginLeft: 15, flexGrow: 1, maxWidth: 150 }}
          />
        </div>
        <div className="row">
          <TextField
            label="Email*"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            size="small"
            sx={{ flexGrow: 2 }}
            disabled={true}
          />
          <TextField
            label="Height"
            InputProps={{ inputProps: { min: 1, max: 500 } }}
            variant="outlined"
            type="number"
            placeholder="Height (cm)"
            value={height}
            onChange={(e) => setHeight(Number(e.target.value))}
            size="small"
            style={{ marginLeft: 15, flexGrow: 1, maxWidth: 100 }}
          />
        </div>
        <div className="buttons">
          <Button variant="outlined" color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={(e) => saveUserInfo.mutate(e)}
          >
            Save
          </Button>
        </div>
      </Box>
    </Modal>
  )
}

EditPatientModal.propTypes = {
  refetch: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpenEditPatientModal: PropTypes.func,
  biovuUserId: PropTypes.string,
  biovuUserOnPortalId: PropTypes.string,
  initialFirstName: PropTypes.string,
  initialLastName: PropTypes.string,
  initialSex: PropTypes.string,
  initialDOB: PropTypes.number,
  initialEmail: PropTypes.string,
  initialPhone: PropTypes.string,
  initialHealthNumber: PropTypes.string,
  initialHeight: PropTypes.number
}
