/* eslint-disable multiline-ternary */
// todo: implement a confirmation modal for deleting things
// todo: re-add the delete functionality for care plans

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Tooltip
} from '@mui/material'
import CarePlansForm from '@shared/care-plans-form/CarePlansForm'
import TopSection from '@shared/top-section/TopSection'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import styles from './CarePlans.module.scss'

import { CurrentPortalContext } from '@context/CurrentPortalContext'
import { UserContext } from '@context/UserContext'
import ClinicvuService from 'services/Clinicvu/Clinicvu.service'

const CarePlans = () => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [allCarePlans, setAllCarePlans] = useState([])
  const [carePlans, setCarePlans] = useState([])
  const [showNew, setShowNew] = useState(false)
  const [selectedCarePlan, setSelectedCarePlan] = useState(null)

  const { currentPortal } = useContext(CurrentPortalContext)
  const { me } = useContext(UserContext)
  const location = useLocation()

  const currentPortalName = me?.portals[currentPortal].portal?.name

  const fetchCarePlans = async () => {
    if (currentPortalName) {
      try {
        const res = await ClinicvuService.getCarePlans(currentPortalName)
        setAllCarePlans(res.data)
        setPage(0)
      } catch (e) {
        console.error(
          `Error occurred while fetching care plans on portal: ${e}`
        )
      }
    }
  }

  useEffect(() => {
    fetchCarePlans()
  }, [showNew, currentPortalName])

  useEffect(() => {
    setCarePlans(allCarePlans.slice(0, rowsPerPage))
  }, [allCarePlans, rowsPerPage])

  useEffect(() => {
    toggleEditOrNew(null, false)
  }, [location.key])

  const handlePageChange = (e, newPage) => {
    setPage(newPage)
    setCarePlans(
      allCarePlans.slice(
        newPage * rowsPerPage,
        newPage * rowsPerPage + rowsPerPage
      )
    )
  }

  const handleRowsPerPageChange = (e) => {
    const newRowsPerPage = parseInt(e.target.value, 10)
    setRowsPerPage(newRowsPerPage)
    setPage(0)
    setCarePlans(allCarePlans.slice(0, newRowsPerPage))
  }

  const toggleEditOrNew = (carePlan, toggle) => {
    setSelectedCarePlan(carePlan)
    setShowNew(toggle)
  }

  return (
    <div className={styles.content}>
      <TopSection btnFunction={toggleEditOrNew} showNew={showNew}></TopSection>
      {!showNew ? (
        <>
          <div className={styles.header}>Care Plans</div>
          <div className={styles.container}>
            <TableContainer component={Paper}>
              <Table aria-label="All Patients Table" className={styles.table}>
                <TableHead className={styles.table__head}>
                  <TableRow>
                    <TableCell width="90%">Name</TableCell>
                    <TableCell width="5%"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {carePlans.map((carePlan, index) => (
                    <TableRow
                      className={styles.tablerow}
                      key={index}
                      onClick={() => toggleEditOrNew(carePlan, true)}
                    >
                      <TableCell>
                        <div className={styles.username}>
                          <span className={styles.username__text}>
                            {carePlan.name}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={styles.buttons}>
                          <Tooltip title="View Care Plan">
                            <button className={styles.buttons__btn}>
                              <img src="/assets/svgs/pencil.svg" alt="Edit" />
                            </button>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleRowsPerPageChange}
                      page={page}
                      onPageChange={handlePageChange}
                      count={allCarePlans.length}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </>
      ) : (
        <>
          <CarePlansForm
            setShowNew={setShowNew}
            carePlan={selectedCarePlan}
            portal={currentPortalName}
            fetchAction={fetchCarePlans}
            btnFunction={toggleEditOrNew}
          />
        </>
      )}
    </div>
  )
}
export default CarePlans
