import moment from 'moment'
import { Auth } from 'aws-amplify'
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber'

export const generateMonthlyPatientsGraphData = (param) => {
  if (!param) return
  const labels = param.map((el) => el.month)
  const data = param.map((el) => el.totalPatients)

  // TODO: Should the color of the graph be another input?
  return {
    labels,
    datasets: [
      {
        label: 'Cumulative Monthly Enrolled Patients',
        data,
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgba(255, 99, 132, 0.2)'
      }
    ]
  }
}

// This function assumes that the x axis is always populated based on the date.
export const generateSingleLineGraphData = (param, graphLabel) => {
  if (!param) return

  const lastParams = param
  const labels = lastParams.map((el) => moment(el.taken_at).format('MM-DD'))
  const data = lastParams.map((el) => (el.value ? el.value : null))

  // TODO: Should the color of the graph be another input?
  return {
    labels,
    datasets: [
      {
        label: graphLabel,
        data,
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgba(255, 99, 132, 0.2)'
      }
    ]
  }
}

export const generateECGGraphData = (param) => {
  if (!param) return

  const lastParams = param[0]
  if (lastParams.values) {
    const data = JSON.parse(lastParams.values)
    return data
  } else {
    return []
  }
}

export const generateSPO2GraphData = (param, graphLabel) => {
  if (!param) return
  const lastParams = param
  const labels = []
  const data = []

  lastParams.forEach((item) => {
    labels.push(moment(item.taken_at).format('MM-DD'))
    data.push(item.value)
  })

  let bgColors = []
  let borderColors = []

  bgColors = data.map((value) =>
    value >= 95 ? '#147AD6' : 'rgba(115, 136, 169, 0.353283)'
  )
  borderColors = lastParams.map((value) =>
    value >= 95 ? 'rgba(121, 210, 222, 0.2)' : 'rgba(115, 136, 169, 0.1)'
  )

  // TODO: Should the color of the graph be another input?
  return {
    labels,
    datasets: [
      {
        label: graphLabel,
        data,
        fill: false,
        backgroundColor: bgColors,
        borderColor: borderColors
      }
    ]
  }
}

export const generateDoubleLineGraphData = (
  paramD1,
  graphLabelD1,
  graphLabelD2,
  slice = true
) => {
  if (!paramD1) return
  const lastParamsD1 = slice ? paramD1 : paramD1
  const labels = lastParamsD1?.map((el) => moment(el.taken_at).format('MM-DD'))
  const data = lastParamsD1?.map((el) => JSON.parse(el.values))
  const data1 = data?.map((el) => el?.sp)
  const data2 = data?.map((el) => el?.dp)

  return {
    labels,
    datasets: [
      {
        label: graphLabelD1,
        data: data1,
        fill: false,
        backgroundColor: '#79D2DE',
        borderColor: 'rgba(121, 210, 222, 0.2)'
      },
      {
        label: graphLabelD2,
        data: data2,
        fill: false,
        backgroundColor: '#147AD6',
        borderColor: 'rgba(20, 122, 214, 0.2)'
      }
    ]
  }
}

export const generateBarGraphData = (param, isSpO2, graphLabel) => {
  if (!param) return

  const lastParams = param
  const labels = lastParams.map((el) => el.date)
  const data = lastParams.map((el) => el.data)

  let bgColors = []
  let borderColors = []
  if (isSpO2) {
    bgColors = lastParams.map((el) =>
      el.data >= 95 ? '#147AD6' : 'rgba(115, 136, 169, 0.353283)'
    )
    borderColors = lastParams.map((el) =>
      el.data >= 95 ? 'rgba(121, 210, 222, 0.2)' : 'rgba(115, 136, 169, 0.1)'
    )
  } else {
    bgColors = lastParams.map((el) => '#147AD6')
    borderColors = lastParams.map((el) => 'rgba(121, 210, 222, 0.2)')
  }

  return {
    labels,
    datasets: [
      {
        label: graphLabel,
        data,
        backgroundColor: bgColors,
        borderColor: borderColors,
        borderWidth: 1
      }
    ]
  }
}

export const generateDoubleLineGraphDataClassic = (
  param,
  graphLabelD1,
  graphLabelD2,
  slice = true
) => {
  if (!param) return

  const lastParams = slice ? param : param
  const labels = lastParams.map((el) => el.date)
  const data1 = lastParams.map((el) => el.data_1)
  const data2 = lastParams.map((el) => el.data_2)

  return {
    labels,
    datasets: [
      {
        label: graphLabelD1,
        data: data1,
        fill: false,
        backgroundColor: '#79D2DE',
        borderColor: 'rgba(121, 210, 222, 0.2)'
      },
      {
        label: graphLabelD2,
        data: data2,
        fill: false,
        backgroundColor: '#147AD6',
        borderColor: 'rgba(20, 122, 214, 0.2)'
      }
    ]
  }
}

export const formatPhoneNumbers = (number) => {
  const phoneUtil = PhoneNumberUtil.getInstance()
  try {
    const tel = phoneUtil.parse(number)
    return phoneUtil.format(tel, PhoneNumberFormat.NATIONAL)
  } catch {}
}

export const formatCarePlan = (patient) => {
  const carePlans = []
  patient.biovu_users_on_portals_on_careplans.forEach(function (item, index) {
    carePlans.push(item.care_plan.name)
  })
  return carePlans.join(', ')
}

export const getAlerts = (patient) => {
  const condensedAlerts = {}
  patient.biovu_users_on_portals_on_careplans.forEach((careplan) => {
    careplan.alerts.forEach((alert) => {
      if (alert.issue in condensedAlerts) {
        condensedAlerts[alert.issue].count += 1
        if (
          alert.alert_at > condensedAlerts[alert.issue].latest_alert.alert_at
        ) {
          condensedAlerts[alert.issue].latest_alert = alert
        }
      } else {
        condensedAlerts[alert.issue] = {
          count: 1,
          latest_alert: alert
        }
      }
    })
  })
  return condensedAlerts
}

export const epochTimeConverter = (epochTime) => {
  if (epochTime) {
    return new Date(epochTime).toLocaleDateString()
  } else {
    return null
  }
}

export const getActionTitle = (action) => {
  switch (action) {
    case 'bt':
      return 'Temperature'
    case 'bp':
      return 'Blood Pressure'
    case 'ecg':
      return 'ECG'
    case 'weight':
      return 'Weight'
    case 'spo2':
      return 'SPO2'
  }
}

export const getJWT = async () => {
  return Auth.currentSession().then((res) => {
    const getIdToken = res.getIdToken()
    const jwt = getIdToken.getJwtToken()
    return jwt
  })
}

export const getMicrophone = (me) => {
  if (me.userType === 'ScreenVU') {
    return false
  } else {
    return true
  }
}

export const getVideo = (me) => {
  if (me.userType === 'ScreenVU') {
    return true
  } else {
    return false
  }
}

export const getWebcam = (me, webcams) => {
  let value = ''
  if (me.userType === 'Screenvu') {
    Object.keys(webcams).forEach((webcam) => {
      const name = webcams[webcam]
      if (name.includes('OBS Virtual Camera')) {
        value = webcam
      }
    })
  }
  return value
}

export const isScreenvu = (me) => {
  return me.userType === 'ScreenVU'
}

export const parseSubscriber = (subscriber) => {
  const userMetadata = JSON.parse(subscriber.stream?.connection?.data)
  const id = subscriber.stream?.streamId
  const type = userMetadata.userType
  const name = userMetadata.clientData
  return { id, type, name }
}

export const isValidEmail = (email) => {
  const emailRe =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (email === undefined || !emailRe.test(email.toLowerCase())) return false
  return true
}

export const capitalize = (str) => {
  const lower = str.toLowerCase()
  return str.charAt(0).toUpperCase() + lower.slice(1)
}

export const isValidNumber = (number) => {
  if (number === null || number === '') {
    return true
  }
  const phoneUtil = PhoneNumberUtil.getInstance()
  try {
    const tel = phoneUtil.parse(number)
    return phoneUtil.isValidNumber(tel)
  } catch {
    return false
  }
}

export const epochToDDMMYY = (epoch) => {
  if (epoch === null) {
    return ''
  }
  return moment(epoch).format('DD/MM/YYYY')
}

export const getAlertCounts = (patient) => {
  const alertCounts = {
    outOfRange: 0,
    missing: 0
  }
  patient.biovu_users_on_portals_on_careplans.forEach((careplan) => {
    careplan.alerts.forEach((alert) => {
      if (alert.issue.includes('Out of range')) {
        alertCounts.outOfRange += 1
      } else if (alert.issue.includes('Missing')) {
        alertCounts.missing += 1
      }
    })
  })
  return alertCounts
}
