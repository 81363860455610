/* eslint-disable multiline-ternary */
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from '@mui/material'
import FileForm from '@shared/file-form/FileForm'
import TopSection from '@shared/top-section/TopSection'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styles from './Files.module.scss'

import { CurrentPortalContext } from '@context/CurrentPortalContext'
import { UserContext } from '@context/UserContext'
import ClinicvuService from 'services/Clinicvu/Clinicvu.service'

const Surveys = () => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [allFiles, setAllFiles] = useState([])
  const [files, setFiles] = useState([])
  const [showNew, setShowNew] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)

  const { currentPortal } = useContext(CurrentPortalContext)
  const { me } = useContext(UserContext)
  const location = useLocation()

  const currentPortalName = me?.portals[currentPortal].portal?.name

  const fetchFiles = async () => {
    if (currentPortalName) {
      try {
        const res = await ClinicvuService.getAllFiles(currentPortalName)
        setAllFiles(res.data)
        setPage(0)
      } catch (e) {
        console.error(`Error occurred while fetching surveys on portal: ${e}`)
      }
    }
  }

  useEffect(() => {
    fetchFiles()
  }, [currentPortalName])

  useEffect(() => {
    setFiles(allFiles.slice(0, rowsPerPage))
  }, [allFiles, rowsPerPage])

  useEffect(() => {
    toggleEditOrNew(null, false)
  }, [location.key])

  const handlePageChange = (e, newPage) => {
    setPage(newPage)
    setFiles(
      allFiles.slice(
        newPage * rowsPerPage,
        newPage * rowsPerPage + rowsPerPage
      )
    )
  }

  const handleRowsPerPageChange = (e) => {
    const newRowsPerPage = parseInt(e.target.value, 10)
    setRowsPerPage(newRowsPerPage)
    setPage(0)
    setFiles(allFiles.slice(0, newRowsPerPage))
  }

  const toggleEditOrNew = (survey, toggle) => {
    setSelectedFile(survey)
    setShowNew(toggle)
  }

  return (
    <div className={styles.content}>
      <TopSection btnFunction={toggleEditOrNew} showNew={showNew}></TopSection>
      {!showNew ? (
        <div>
          <div className={styles.header}>Files</div>
          <div className={styles.container}>
            <TableContainer component={Paper}>
              <Table aria-label="Surveys Table" className={styles.table}>
                <TableHead className={styles.table__head}>
                  <TableRow>
                    <TableCell width="90%">Name</TableCell>
                    <TableCell width="5%"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files.map((survey, index) => (
                    <TableRow
                      className={styles.tablerow}
                      key={index}
                      onClick={() => toggleEditOrNew(survey, true)}
                    >
                      <TableCell>{survey.name}</TableCell>
                      <TableCell>
                        <div className={styles.buttons}>
                          <Tooltip title="View Survey">
                            <button className={styles.buttons__btn}>
                              <img src="/assets/svgs/pencil.svg" alt="Edit" />
                            </button>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleRowsPerPageChange}
                      page={page}
                      onPageChange={handlePageChange}
                      count={allFiles.length}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>
      ) : (
        <div>
          <FileForm
            btnFunction={toggleEditOrNew}
            file={selectedFile}
            portal={currentPortalName}
            fetchAction={fetchFiles}
          />
        </div>
      )}
    </div>
  )
}

export default Surveys
