export const AlertsData = [
  {
    patientName: 'Leslie Alexander',
    carePlan: 'CHF',
    missing: null,
    outOfRange: 'Blood Pressure',
    actions: null
  },
  {
    patientName: 'Leslie Alexander',
    carePlan: 'CHF',
    missing: 'ECG',
    outOfRange: null,
    actions: null
  },
  {
    patientName: 'Leslie Alexander',
    carePlan: 'CHF',
    missing: null,
    outOfRange: 'Weight',
    actions: null
  },
  {
    patientName: 'Parsa T',
    carePlan: 'TTT',
    missing: null,
    outOfRange: 'Glucose',
    actions: null
  },
  {
    patientName: 'Farbod',
    carePlan: 'ABC',
    missing: 'Weight',
    outOfRange: null,
    actions: null
  },
  {
    patientName: 'Matt Dudeson',
    carePlan: 'CHF',
    missing: 'SpO2',
    outOfRange: 'Weight',
    actions: null
  }
]
