export const EmptyGraph = {
  labels: [],
  datasets: [
    {
      label: 'Fetching Data',
      data: [],
      fill: false
    }
  ]
}

export const CarePlanOptions = [
  {
    name: 'CHF',
    id: 1
  },
  {
    name: 'Test',
    id: 2
  }
]

export const PatientDataTypes = [
  'ECG',
  'Weight',
  'Temprature',
  'Blood Pressure',
  'SpO2',
  'Glucose',
  'All'
]

export const CarePlansColors = {
  '#9C27B0': 'Purple',
  '#E91E63': 'Pink',
  '#3F51B5': 'Indigo',
  '#009688': 'Teal',
  '#00BCD4': 'Cyan'
}

export const ParamFrequencies = { day: 'Day', week: 'Week', month: 'Month' }

export const ParamGender = { male: 'Male', female: 'Female', other: 'Other' }

export const ParamTimeZones = {
  'Canada/Atlantic': 'Canada/Atlantic',
  'Canada/Central': 'Canada/Central',
  'Canada/Eastern': 'Canada/Eastern',
  'Canada/Mountain': 'Canada/Mountain',
  'Canada/Newfoundland': 'Canada/Newfoundland',
  'Canada/Pacific': 'Canada/Pacific',
  'Canada/Saskatchewan': 'Canada/Saskatchewan',
  'Canada/Yukon': 'Canada/Yukon'
}

export const PatientRequirement = [
  {
    action: 'bt',
    frequency: 1,
    period: 'day',
    type: 'absolute',
    normal_range_min: 36.1,
    normal_range_max: 37.2,
    change_time_frame: null,
    change_value: null,
    survey: null,
    file: null
  },
  {
    action: 'survey',
    frequency: 1,
    period: 'week',
    type: 'none',
    normal_range_min: null,
    normal_range_max: null,
    change_time_frame: null,
    change_value: null,
    survey: 'General'
  }
]

export const MeasurementTypes = {
  bp: 'Blood Pressure',
  bt: 'Temperature',
  spo2: 'SPO2',
  ecg: 'ECG',
  survey: 'Survey',
  weight: 'Weight',
  file: 'File'
}
// weight: "Weight",
// survey: "Survey",

export const AlertTypes = {
  none: 'None',
  absolute: 'Absolute'
}
// relative: "Relative",

export const EWSStat = ['Green', 'Yellow', 'Orange', 'Red']

export const patientModalStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
}

export const resolutionValues = {
  MD: 'Medium Defintion (480p)',
  HD: 'High Definition (720p)',
  'Full HD': 'Full HD (1080p)',
  'Ultra HD': 'Ultra HD (4k)'
}

export const resolutionNumbers = {
  MD: '640×480p',
  HD: '1280x720',
  'Full HD': '1920x1080',
  'Ultra HD': '3840x2160'
}

export const callCodecValues = { none: 'None', VP8: 'VP8', H264: 'H264' }

export const recordingModeValues = { basic: 'Basic', advanced: 'Advanced' }

export const permissionConstraints = { audio: true, video: true }

export const resolutionOptions = [
  'Ultra HD',
  'Full HD',
  'HD',
  'MD',
  'Video Off'
]

export const snackBarAnchorOrigin = { vertical: 'bottom', horizontal: 'center' }
