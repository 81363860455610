import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Modal,
  TextField,
  Typography
} from '@mui/material'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { CurrentPortalContext } from '@context/CurrentPortalContext'
import { UserContext } from '@context/UserContext'
import PropTypes from 'prop-types'

import styles from './RegisterUser.module.scss'
import ClinicvuService from 'services/Clinicvu/Clinicvu.service'
import { isValidEmail } from 'utils/functions'

const confirmationModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
}

const RegisterUser = ({ btnFunction, user }) => {
  const [firstName, setFirstName] =
    user?.clinicvu_user?.first_name == null
      ? useState('')
      : useState(user?.clinicvu_user?.first_name)
  const [lastName, setLastName] =
    user?.clinicvu_user?.last_name == null
      ? useState('')
      : useState(user?.clinicvu_user?.last_name)

  const [carePlanToBeUnselected, setCarePlanToBeUnselected] = useState(null)
  const [selectedCarePlans, setSelectedCarePlans] = useState([])

  const [role, setRole] =
    user?.role === null ? useState('') : useState(user?.role)
  const [email, setEmail] =
    user?.clinicvu_user?.email == null
      ? useState('')
      : useState(user?.clinicvu_user?.email)

  const [emailInvalid, setEmailInvalid] = useState(false)
  const [firstNameInvalid, setFirstNameInvalid] = useState(false)
  const [lastNameInvalid, setLastNameInvalid] = useState(false)
  const [roleInvalid, setRoleInvalid] = useState(false)
  const [attempted, setAttempted] = useState(false)

  const { currentPortal } = useContext(CurrentPortalContext)
  const { me } = useContext(UserContext)
  const queryClient = useQueryClient()

  const currentPortalName = me?.portals[currentPortal]?.portal?.name

  const currentCarePlans = user
    ? [...user?.clinicvu_users_on_portals_on_care_plans].map(
        (cp) => cp.care_plan_id
      )
    : []

  const { data: carePlansOnPortal } = useQuery(
    ['carePlansOnPortal'],
    async () => {
      const res = await ClinicvuService.getCarePlans(currentPortalName)
      if (res.status !== 200) {
        throw new Error(
          `Was not able to fetch careplans data for portal ${currentPortalName}`
        )
      }
      return res.data
    },
    {
      enabled: !!currentPortalName
    }
  )

  useEffect(() => {
    setSelectedCarePlans(currentCarePlans || [])
  }, [])

  useEffect(() => {
    // Attempted allows us to only flag empty fields after an attempted submit.
    if (attempted) {
      setFirstNameInvalid(firstName === '')
      setLastNameInvalid(lastName === '')
      setRoleInvalid(role === '')
      setEmailInvalid(!isValidEmail(email))
    } else if (email !== '') setEmailInvalid(!isValidEmail(email))
  }, [email, attempted, firstName, lastName, role, attempted])

  const saveUserInfo = useMutation(async (e) => {
    e.preventDefault()
    setAttempted(true)
    if (
      isValidEmail(email) &&
      firstName !== '' &&
      lastName !== '' &&
      role !== ''
    ) {
      const res = await ClinicvuService.createNewClinicvuUser(
        firstName,
        lastName,
        currentPortalName,
        email,
        role.value || role,
        selectedCarePlans,
        user?.id
      )
      if (res.status !== 200) {
        throw new Error('Was unable to save user information with id')
      }
      queryClient.invalidateQueries('clinicvuUsersOnPortal')
      btnFunction(null, false)
    }
  })

  const handleToggleCarePlan = (carePlan) => {
    if (selectedCarePlans.includes(carePlan.id)) {
      setCarePlanToBeUnselected(carePlan)
    } else {
      const newSelectedCarePlans = [...selectedCarePlans, carePlan.id]
      setSelectedCarePlans(newSelectedCarePlans)
    }
  }

  // todo: we have some repition here, think about refactoring this section later
  const unselectCarePlan = (carePlan) => {
    const carePlanIndex = selectedCarePlans.indexOf(carePlan.id)
    const newSelectedCarePlans = [...selectedCarePlans]
    newSelectedCarePlans.splice(carePlanIndex, 1)
    setSelectedCarePlans(newSelectedCarePlans)
    setCarePlanToBeUnselected(null)
  }

  return (
    <>
      <div className={styles['register-user']}>
        <form className={styles['register-user__form']}>
          <div className={styles.row}>
            <label className={styles['single-wide']}>
              <TextField
                label="Email*"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                size="small"
                disabled={!!user?.clinicvu_user?.email}
                error={emailInvalid}
                helperText={emailInvalid ? 'Please enter a valid email' : ''}
              />
            </label>
          </div>

          <div className={styles.row}>
            <label className={styles['double-wide']}>
              <TextField
                label="First name*"
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                size="small"
                disabled={!!user?.clinicvu_user?.first_name}
                error={firstNameInvalid}
                helperText={firstNameInvalid ? 'First name is required' : ''}
              />
            </label>
            <label className={styles['double-wide']}>
              <TextField
                label="Last name*"
                variant="outlined"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                size="small"
                disabled={!!user?.clinicvu_user?.last_name}
                error={lastNameInvalid}
                helperText={lastNameInvalid ? 'Last name is required' : ''}
              />
            </label>
          </div>

          <div className={styles.row}>
            <label className={styles['single-wide']}>
              <TextField
                label="Role*"
                value={role}
                size="small"
                onChange={(e) => setRole(e.target.value)}
                disabled={!!user?.role}
                error={roleInvalid}
                select
              >
                <MenuItem value="Clinician">Clinician</MenuItem>
                <MenuItem value="Manager">Manager</MenuItem>
              </TextField>
            </label>
          </div>

          <Typography variant="h6" component="div">
            Care Plans
          </Typography>

          <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          >
            {carePlansOnPortal?.map((carePlan, index) => {
              const labelId = `care-plan-${carePlan?.name}`

              return (
                <ListItem key={index} disablePadding sx={{ height: '35px' }}>
                  <ListItemButton
                    role={undefined}
                    onClick={() => handleToggleCarePlan(carePlan)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selectedCarePlans.includes(carePlan.id)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                        sx={{ padding: 0 }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={`${carePlan?.name}`}
                      sx={{ marginLeft: '-30px' }}
                    />
                  </ListItemButton>
                </ListItem>
              )
            })}
          </List>

          <div className={`${styles.row} ${styles['row--spaced']}`}>
            <div className={`${styles['btn--margined']}`}></div>
            <div>
              <button
                className={`${styles.btn} ${styles['btn--red']}`}
                onClick={() => btnFunction(null, false)}
              >
                Cancel
              </button>
              <button
                className={styles.btn}
                onClick={(e) => saveUserInfo.mutate(e)}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>

      <Modal
        open={!!carePlanToBeUnselected}
        onClose={() => setCarePlanToBeUnselected(null)}
        aria-labelledby="careplans-confirmation-modal"
        aria-describedby="Confirm unselecting a care plan"
      >
        <Box sx={confirmationModalStyle}>
          <Typography variant="h6" component="h2">
            Are you sure?
          </Typography>
          <Typography>
            Unselecting the {`${carePlanToBeUnselected?.name}`} care plan will
            dissociate the user with all the patients on it.
          </Typography>
          <div className={`${styles.row} ${styles['row--spaced']}`}>
            <button
              className={styles.btn}
              onClick={() => setCarePlanToBeUnselected(null)}
            >
              Cancel
            </button>
            <button
              className={`${styles.btn} ${styles['btn--red']}`}
              onClick={() => unselectCarePlan(carePlanToBeUnselected)}
            >
              Unselect Care Plan
            </button>
          </div>
        </Box>
      </Modal>
    </>
  )
}

RegisterUser.propTypes = {
  btnFunction: PropTypes.func,
  user: PropTypes.object
}

export default RegisterUser
