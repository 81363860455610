import React, { useState } from 'react'
import 'react-dropdown/style.css'
import styles from './FileForm.module.scss'
import ClinicvuService from 'services/Clinicvu/Clinicvu.service'
import { TextField } from '@mui/material'

const FileForm = ({ btnFunction, file, portal, fetchAction }) => {
  const [fileName, setFileName] = useState(file?.name)
  const [fileContent, setFileContent] = useState(null)

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]
    setFileContent(selectedFile || null)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (fileName && fileContent) {
      const formData = new FormData()
      formData.append('fileName', fileName)
      formData.append('file', fileContent)
      formData.append('portalName', portal)
      await ClinicvuService.createFile(formData)
      btnFunction(null, false)
      fetchAction()
    }
  }

  return (
    <div>
      <div className={styles.header}>{file ? 'View File' : 'New File'}</div>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.row}>
          <TextField
            disabled={file !== null}
            label="File Name"
            required
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
            size="small"
            sx={{ marginLeft: '15px' }}
          />
        </div>
        <div className={styles.row}>
          {!file
            ? (
            <input
              type="file"
              accept=".txt,.pdf,.rtf"
              onChange={handleFileChange}
              required
            />
              )
            : null}
        </div>
        <div>
          <button
            className={styles['btn-cancel']}
            type="button"
            onClick={() => btnFunction(null, false)}
          >
            Cancel
          </button>
          {!file ? <button type="submit">Save</button> : null}
        </div>
      </form>
    </div>
  )
}

export default FileForm
