import { MenuItem, Select, TextField } from '@mui/material'

import {
  AlertTypes,
  MeasurementTypes,
  ParamFrequencies
} from '@utils/constants'
import React from 'react'

import PropTypes from 'prop-types'
import 'react-dropdown/style.css'
import styles from './MeasurementsForm.module.scss'
import { getActionTitle } from '@utils/functions'

const MeasurementsForm = ({
  requirement,
  handleRequirementChange,
  handleCustomRequirementChange,
  index,
  surveys,
  removeRequirement,
  files,
  carePlan
}) => {
  const getSurveyName = () => {
    if (requirement.surveys_on_care_plans_id !== undefined) {
      // we're editing a care plan.
      return requirement.surveys_on_care_plans.survey.name
    } else if (requirement.survey !== '') {
      // we're creating a new care plan.
      return surveys.find((survey) => survey.id === requirement.survey)?.name
    } else {
      return ''
    }
  }

  const getFileName = () => {
    if (requirement.files_on_care_plans_id !== undefined) {
      // we're editing a care plan.
      return requirement.files_on_care_plans.file.name
    } else if (requirement.file) {
      return files?.find((file) => file.id === requirement.file)?.name
    }
  }

  return (
    <div>
      <div className={styles.row}>
        <div className={styles.param}>
          <div className={styles.param__header}>
            <div className={styles.row_title}>Type</div>
            <Select
              size="small"
              value={requirement.action}
              onChange={(e) =>
                handleRequirementChange(e.target.value, index, 'action')
              }
              className={styles.param__input}
              disabled={!!requirement.id}
            >
              {Object.keys(MeasurementTypes)?.map((item, index) => {
                return (
                  <MenuItem value={item} key={index}>
                    {MeasurementTypes[item]}
                  </MenuItem>
                )
              })}
            </Select>
          </div>
          {requirement.action === 'survey'
            ? (
            <>
              <div className={styles.row_title}>Survey Name</div>
              <Select
                value={getSurveyName()}
                onChange={(e) => {
                  const surveyId = surveys.find(
                    (survey) => survey.name === e.target.value
                  )?.id
                  handleRequirementChange(surveyId, index, 'survey')
                }}
                className={styles.param__input}
                disabled={!!requirement.id}
              >
                {surveys?.map((item, index) => {
                  return (
                    <MenuItem value={item.name} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </>
              )
            : null}
          {requirement.action === 'file'
            ? (
            <>
              <div className={styles.row_title}>File Name</div>
              <Select
                value={getFileName()}
                onChange={(e) => {
                  const fildId = files.find(
                    (file) => file.name === e.target.value
                  )?.id
                  handleRequirementChange(fildId, index, 'file')
                }}
                className={styles.param__input}
                disabled={!!requirement.id}
              >
                {files?.map((item, index) => {
                  return (
                    <MenuItem value={item.name} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </>
              )
            : null}
          {requirement.action !== 'file' && (
            <div>
              <div className={styles.row_title}>Frequency</div>
              <div className={styles.params_row}>
                <TextField
                  type="number"
                  size="small"
                  disabled={!!requirement.id}
                  value={requirement.frequency}
                  variant="outlined"
                  InputProps={{ inputProps: { min: 1, max: 100 } }}
                  onChange={(e) =>
                    handleRequirementChange(
                      Number(e.target.value),
                      index,
                      'frequency'
                    )
                  }
                />
                <div>Per</div>
                <Select
                  size="small"
                  value={requirement.period}
                  disabled={!!requirement.id}
                  onChange={(e) =>
                    handleRequirementChange(e.target.value, index, 'period')
                  }
                >
                  {Object.keys(ParamFrequencies)?.map((item, index) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {ParamFrequencies[item]}
                      </MenuItem>
                    )
                  })}
                </Select>
              </div>
            </div>
          )}
          {!['survey', 'ecg', 'file'].includes(requirement.action)
            ? (
            <div>
              <div className={styles.row_title}>Alert Type</div>
              <Select
                size="small"
                value={requirement.type}
                onChange={(e) =>
                  handleRequirementChange(e.target.value, index, 'type')
                }
                disabled={!!requirement.id}
                className={styles.param__input}
              >
                {Object.keys(AlertTypes)?.map((item, index) => {
                  return (
                    <MenuItem value={item} key={index}>
                      {AlertTypes[item]}
                    </MenuItem>
                  )
                })}
              </Select>
              {requirement.type === 'none'
                ? null
                : requirement.type ===
                'absolute'
                  ? (
                      requirement.action !== 'bp'
                        ? (
                  <div>
                    <TextField
                      size="small"
                      disabled={!!requirement.id}
                      type="number"
                      value={requirement.normal_range_min}
                      variant="outlined"
                      InputProps={{ inputProps: { min: 1, max: 1000000 } }}
                      placeholder={`Lower ${getActionTitle(
                        requirement.action
                      )} Threshold`}
                      className={styles.param__threshold_input}
                      onInput={(e) =>
                        handleRequirementChange(
                          Number(e.target.value),
                          index,
                          'normal_range_min'
                        )
                      }
                    />
                    <TextField
                      size="small"
                      type="number"
                      disabled={!!requirement.id}
                      value={requirement.normal_range_max}
                      variant="outlined"
                      InputProps={{ inputProps: { min: 1, max: 1000000 } }}
                      placeholder={`Upper ${getActionTitle(
                        requirement.action
                      )} Threshold`}
                      className={styles.param__threshold_input}
                      onInput={(e) =>
                        handleRequirementChange(
                          Number(e.target.value),
                          index,
                          'normal_range_max'
                        )
                      }
                    />
                  </div>
                          )
                        : (
                  <div>
                    <TextField
                      size="small"
                      disabled={!!requirement.id}
                      type="number"
                      value={requirement.normal_range_min_custom?.sp}
                      variant="outlined"
                      InputProps={{ inputProps: { min: 1, max: 1000000 } }}
                      placeholder="Lower Systolic Threshold"
                      className={styles.param__threshold_input}
                      onInput={(e) =>
                        handleCustomRequirementChange(
                          Number(e.target.value),
                          index,
                          'normal_range_min_custom',
                          'sp'
                        )
                      }
                    />
                    <TextField
                      size="small"
                      disabled={!!requirement.id}
                      type="number"
                      value={requirement.normal_range_max_custom?.sp}
                      variant="outlined"
                      InputProps={{ inputProps: { min: 1, max: 1000000 } }}
                      placeholder="Upper Systolic Threshold"
                      className={styles.param__threshold_input}
                      onInput={(e) =>
                        handleCustomRequirementChange(
                          Number(e.target.value),
                          index,
                          'normal_range_max_custom',
                          'sp'
                        )
                      }
                    />
                    <TextField
                      size="small"
                      type="number"
                      disabled={!!requirement.id}
                      value={requirement.normal_range_min_custom?.dp}
                      variant="outlined"
                      InputProps={{ inputProps: { min: 1, max: 1000000 } }}
                      placeholder="Lower Diastolic Threshold"
                      className={styles.param__threshold_input}
                      onInput={(e) =>
                        handleCustomRequirementChange(
                          Number(e.target.value),
                          index,
                          'normal_range_min_custom',
                          'dp'
                        )
                      }
                    />
                    <TextField
                      size="small"
                      type="number"
                      disabled={!!requirement.id}
                      value={requirement.normal_range_max_custom?.dp}
                      variant="outlined"
                      InputProps={{ inputProps: { min: 0, max: 1000000 } }}
                      placeholder="Upper Diastolic Threshold"
                      className={styles.param__threshold_input}
                      onInput={(e) =>
                        handleCustomRequirementChange(
                          Number(e.target.value),
                          index,
                          'normal_range_max_custom',
                          'dp'
                        )
                      }
                    />
                  </div>
                          )
                    )
                  : requirement.type === 'relative'
                    ? (
                <div>
                  <TextField
                    type="number"
                    value={requirement.change_value}
                    variant="outlined"
                    InputProps={{ inputProps: { min: 0, max: 1000000 } }}
                    placeholder="Change Value"
                    className={styles.param__input}
                    onInput={(e) =>
                      handleRequirementChange(
                        Number(e.target.value),
                        index,
                        'change_value'
                      )
                    }
                  />
                  <TextField
                    type="number"
                    value={requirement.change_time_frame}
                    variant="outlined"
                    InputProps={{ inputProps: { min: 0, max: 1000000 } }}
                    placeholder="Timeframe (days)"
                    className={styles.param__input}
                    onInput={(e) =>
                      handleRequirementChange(
                        Number(e.target.value),
                        index,
                        'change_time_frame'
                      )
                    }
                  />
                </div>
                      )
                    : null}
            </div>
              )
            : null}
          <button
            onClick={() => removeRequirement(index)}
            className={styles.save__btn}
            disabled={!!requirement.id}
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  )
}

MeasurementsForm.propTypes = {
  requirement: PropTypes.object,
  handleRequirementChange: PropTypes.func,
  handleCustomRequirementChange: PropTypes.func,
  index: PropTypes.number,
  surveys: PropTypes.array,
  removeRequirement: PropTypes.func
}

export default MeasurementsForm
