import AllPortalsTable from '@shared/all-portals-table/AllPortalsTable'
import AddNewPortalForm from '@shared/add-new-portal-form/AddNewPortalForm'
import TopSection from '@shared/top-section/TopSection'
import React, { useState } from 'react'
import styles from './Portals.module.scss'

const Portals = () => {
  const [showNew, setShowNew] = useState(false)

  const addPortal = () => {
    setShowNew(!showNew)
  }

  return (
    <div className={styles.content}>
      <TopSection btnFunction={addPortal}></TopSection>
      {!showNew
        ? (
        <div>
          <AllPortalsTable />
        </div>
          )
        : (
        <div>
          <AddNewPortalForm
            setShowNew={setShowNew}
            goBack={() => setShowNew(false)}
          />
        </div>
          )}
    </div>
  )
}

export default Portals
