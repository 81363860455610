import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React, { useContext, useState } from 'react'
import { TableFooter, TablePagination } from '@mui/material'
import styles from './AllPortalsTable.module.scss'
import { useQuery } from 'react-query'
import { CurrentPortalContext } from '@context/CurrentPortalContext'
import { UserContext } from '@context/UserContext'
import ClinicvuService from 'services/Clinicvu/Clinicvu.service'

const AllPortalsTable = () => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { currentPortal } = useContext(CurrentPortalContext)
  const { me } = useContext(UserContext)
  const currentPortalName = me?.portals[currentPortal].portal?.name
  const { data: portals } = useQuery(
    ['portals'],
    async () => {
      const res = await ClinicvuService.fetchAllPortals()
      if (res.status !== 200) {
        throw new Error(
          `Was not able to fetch all patients on portal ${currentPortalName}`
        )
      }
      return res.data
    },
    {
      enabled: !!me
    }
  )
  const currentPortals =
    portals?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) || []

  const handlePageChange = (e, newPage) => {
    setPage(newPage)
  }

  const handleRowsPerPageChange = (e) => {
    const newRowsPerPage = parseInt(e.target.value, 10)
    setRowsPerPage(newRowsPerPage)
    setPage(0)
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>Portals</div>
      <TableContainer component={Paper}>
        <Table aria-label="All Patients Table" className={styles.table}>
          <TableHead className={styles.table__head}>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Licences</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPortals.map((portal, index) => (
              <TableRow className={styles.tablerow} key={index}>
                <TableCell>{portal.name}</TableCell>
                <TableCell align="right">
                  {portal.biovu_user_licences}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={page}
                onPageChange={handlePageChange}
                count={portals?.length || 0}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  )
}

export default AllPortalsTable
