import React, { useEffect, useReducer, useState } from 'react'

import 'react-dropdown/style.css'
import styles from './SurveyForm.module.scss'
import {
  Alert,
  Box,
  Button,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'

import PropTypes from 'prop-types'

import ClinicvuService from 'services/Clinicvu/Clinicvu.service'

const questionToMultipleChoiceModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: '20px'
}

const mcButtonStyle = {
  borderRadius: '50%',
  width: '60px',
  height: '60px',
  fontSize: '140%',
  backgroundColor: '#2196f3'
}

const surveyReducer = (state, action) => {
  switch (action.type) {
    case 'init-survey': {
      return {
        name: action.payload.name,
        questions: action.payload.questions
      }
    }
    case 'set-name': {
      return { ...state, name: action.payload }
    }
    case 'set-question': {
      const newQuestions = [...state.questions]
      const { value, index, param } = action.payload
      newQuestions[index][param] = value
      if (value === 'Yes / No') {
        newQuestions[index].question_choices = [
          { choice_number: 0, choice_text: 'Yes' },
          { choice_number: 1, choice_text: 'No' }
        ]
      } else if (value === 'Likert Scale') {
        newQuestions[index].question_choices = [
          { choice_number: 0, choice_text: 'Strongly Agree' },
          { choice_number: 1, choice_text: 'Agree' },
          { choice_number: 2, choice_text: 'Neutral' },
          { choice_number: 3, choice_text: 'Disagree' },
          { choice_number: 4, choice_text: 'Strongly Disagree' }
        ]
      } else if (value === 'Multiple Choice') {
        newQuestions[index].question_choices = [
          { choice_number: 0, choice_text: 'Option 1' },
          { choice_number: 1, choice_text: 'Option 2' },
          { choice_number: 2, choice_text: 'Option 3' }
        ]
      } else if (value === 'File') {
        newQuestions[index].file = null
      }
      return { ...state, questions: newQuestions }
    }
    case 'set-question-choice': {
      const { question, value, index } = action.payload
      const newQuestions = [...state.questions]
      newQuestions[question.index].question_choices[index].choice_text = value
      return { ...state, questions: newQuestions }
    }
    case 'add-new-question': {
      action.payload.preventDefault()
      const newQuestions = [
        ...state.questions,
        {
          number: '',
          text: '',
          type: 'Yes / No',
          question_choices: [
            { choice_number: 0, choice_text: 'Yes' },
            { choice_number: 1, choice_text: 'No' }
          ]
        }
      ]
      return { ...state, questions: newQuestions }
    }
    case 'remove-last-mc-option': {
      const newQuestions = [...state.questions]
      newQuestions[action.payload.index].question_choices.pop()
      return { ...state, questions: newQuestions }
    }
    case 'add-mc-option': {
      const newQuestions = [...state.questions]
      const optionIndex = action.payload.question_choices.length
      newQuestions[action.payload.index].question_choices.push({
        choice_number: optionIndex,
        choice_text: ''
      })
      return { ...state, questions: newQuestions }
    }
  }
}

const SurveyForm = ({ btnFunction, survey, portal, fetchAction }) => {
  const [questionToMultipleChoice, setQuestionToMultipleChoice] = useState(null)

  const [surveyData, dispatch] = useReducer(surveyReducer, {
    name: '',
    questions: []
  })

  const openMCModal = (e, question, index) => {
    e.preventDefault()
    setQuestionToMultipleChoice({ ...question, index })
  }

  useEffect(() => {
    if (survey) {
      try {
        ;(async () => {
          const res = await ClinicvuService.getSurvey(portal, survey.name)
          const fullSurvey = res.data
          dispatch({
            type: 'init-survey',
            payload: { questions: fullSurvey.questions, name: survey.name }
          })
        })()
      } catch (e) {
        console.error(`Error while fetching survey data: ${e}`)
      }
    }
  }, [survey])

  const findFormError = () => {
    const questionNumbers = surveyData.questions.map(
      (question) => question.number
    )
    const questionText = surveyData.questions.map((question) => question.text)
    if (!surveyData.name || surveyData.name.length === 0) {
      return 'Some of the required fields are missing/incorrect. Make sure that you have entered all the information correctly.'
    } else if (questionText.some((item) => item === '')) {
      return 'One or more of your questions are blank.'
    } else if (new Set(questionNumbers).size !== questionNumbers.length) {
      return "There's an error in your numbering. Make sure each question is numbered correctly and there are no duplicates."
    } else if (surveyData.questions.length === 0) {
      return 'Your survey must have at least one question.'
    } else return ''
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const error = findFormError()
    if (error.length === 0) {
      try {
        console.log(surveyData)
        await ClinicvuService.createSurvey(
          portal,
          surveyData.name,
          surveyData.questions,
          'english'
        )
        btnFunction(null, false)
        fetchAction()
      } catch (e) {
        console.error(`Error occured when posting survey information: ${e}`)
      }
    } else {
      alert(error)
    }
  }

  return (
    <div>
      <div className={styles.header}>
        {survey ? 'View Survey' : 'Define Survey'}
      </div>
      {surveyData && (
        <form className={styles.form}>
          <div className={styles.row}>
            <TextField
              disabled={survey !== null}
              label="Survey Name"
              required
              value={surveyData.name}
              size="small"
              onChange={(e) =>
                dispatch({ type: 'set-name', payload: e.target.value })
              }
              sx={{ marginLeft: '15px' }}
            />
          </div>
          {surveyData.questions.map((question, index) => (
            <React.Fragment key={`question-${index}`}>
              <div className={styles.row}>
                <Select
                  disabled={survey !== null}
                  size="small"
                  labelId="answer-type-dropdown"
                  value={question.number}
                  onChange={(e) =>
                    dispatch({
                      type: 'set-question',
                      payload: {
                        value: e.target.value,
                        index,
                        param: 'number'
                      }
                    })
                  }
                  sx={{ marginLeft: '15px' }}
                  className={styles.row__number}
                >
                  {surveyData.questions.map((content, i) => {
                    return (
                      <MenuItem key={i} value={i + 1}>
                        {i + 1}
                      </MenuItem>
                    )
                  })}
                </Select>

                <TextField
                  disabled={survey !== null}
                  label="Question"
                  value={question.text}
                  size="small"
                  onChange={(e) =>
                    dispatch({
                      type: 'set-question',
                      payload: {
                        value: e.target.value,
                        index,
                        param: 'text'
                      }
                    })
                  }
                  sx={{ marginLeft: '15px' }}
                  className={styles.row__question}
                />

                <Select
                  disabled={survey !== null}
                  size="small"
                  labelId="answer-type-dropdown"
                  value={question.type}
                  onChange={(e) =>
                    dispatch({
                      type: 'set-question',
                      payload: {
                        value: e.target.value,
                        index,
                        param: 'type'
                      }
                    })
                  }
                  className={styles.row__type}
                >
                  <MenuItem value={'Yes / No'}>Yes / No</MenuItem>
                  <MenuItem value={'Likert Scale'}>Likert Scale</MenuItem>
                  <MenuItem value={'Multiple Choice'}>Multiple Choice</MenuItem>
                  <MenuItem value={'File'}>File</MenuItem>

                </Select>
              </div>
              {question.type !== 'File' && (
              <div className={styles['question-choices']}>
                <div>
                  {question.question_choices?.map((choice, index) => (
                    <p key={`question-choice-${index}`}>{choice.choice_text}</p>
                  ))}
                </div>
                {survey === null && (
                  <Tooltip
                    title={survey === null ? 'Edit Question Options' : ''}
                  >
                    <span className={styles['question-choices__span']}>
                      <button
                        disabled={survey !== null}
                        className={styles['question-choices__btn']}
                        onClick={(e) => openMCModal(e, question, index)}
                      >
                        <img src="/assets/svgs/pencil.svg" alt="Edit" />
                      </button>
                    </span>
                  </Tooltip>
                )}
              </div>)}
            </React.Fragment>
          ))}
          <div className={styles.btns}>
            {!survey
              ? (
              <button
                className={styles['btn-primary']}
                onClick={(e) => {
                  dispatch({ type: 'add-new-question', payload: e })
                  dispatch({
                    type: 'set-question',
                    payload: {
                      value: surveyData.questions.length + 1,
                      index: surveyData.questions.length,
                      param: 'number'
                    }
                  })
                }}
              >
                Add a new field
              </button>
                )
              : null}
            <div>
              <button
                className={styles['btn-cancel']}
                onClick={() => btnFunction(null, false)}
              >
                Cancel
              </button>
              {!survey ? <button onClick={handleSubmit}>Save</button> : null}
            </div>
          </div>
        </form>
      )}

      {questionToMultipleChoice && (
        <Modal
          open={!!questionToMultipleChoice}
          onClose={() => setQuestionToMultipleChoice(null)}
          aria-labelledby="Survey Multiple Choice Options Modal"
          aria-describedby="This modal allows the clinician to select the number of multiple choice options and their exact specifications"
        >
          <Box sx={questionToMultipleChoiceModalStyle}>
            <Typography variant="h4" id="survey-mc__header">
              Select the number of options
            </Typography>

            <div className={styles['mcModal__num-questions']}>
              <Button
                variant="contained"
                sx={mcButtonStyle}
                disabled={questionToMultipleChoice.question_choices.length <= 2}
                onClick={() =>
                  dispatch({
                    type: 'remove-last-mc-option',
                    payload: questionToMultipleChoice
                  })
                }
              >
                -
              </Button>
              <p>{questionToMultipleChoice.question_choices.length}</p>
              <Button
                variant="contained"
                sx={mcButtonStyle}
                disabled={questionToMultipleChoice.question_choices.length >= 6}
                onClick={() =>
                  dispatch({
                    type: 'add-mc-option',
                    payload: questionToMultipleChoice
                  })
                }
              >
                +
              </Button>
            </div>

            {questionToMultipleChoice?.question_choices?.map(
              (choice, index) => (
                <TextField
                  key={`question-option-text-${index}`}
                  variant="outlined"
                  value={choice.choice_text}
                  onChange={(e) =>
                    dispatch({
                      type: 'set-question-choice',
                      payload: {
                        question: questionToMultipleChoice,
                        value: e.target.value,
                        index
                      }
                    })
                  }
                />
              )
            )}

            <Alert severity="info">A maximum of 6 options can be created</Alert>

            <div className={styles['mcModal__num-questions']}>
              <Button
                variant="contained"
                sx={{ backgroundColor: '#777' }}
                onClick={() => setQuestionToMultipleChoice(null)}
              >
                Close
              </Button>
              {/* <Button variant="contained" sx={{ backgroundColor: "#2196f3" }}>
                Save
              </Button> */}
            </div>
          </Box>
        </Modal>
      )}
    </div>
  )
}

SurveyForm.propTypes = {
  btnFunction: PropTypes.func,
  survey: PropTypes.any,
  portal: PropTypes.string,
  fetchAction: PropTypes.func
}

export default SurveyForm
